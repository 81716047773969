import toast, { toastConfig } from "react-simple-toasts";
import { hasData } from "../hasData";
import { blockactions } from "@/lib/config";

export const followHandler = async (
  id,
  bol_following,
  user_name,
  isConfirmed,
  set_state_following_them_status,
  set_state_following_them
) => {
  var confirmed = true;
  if (bol_following) {
    //do confirmation check
    confirmed = await confirm(
      `Are you sure you want to unfollow ${user_name}?`
    );
  }

  if (!bol_following || (bol_following && confirmed)) {
    //optimistic update - confirmed after json returns;
    set_state_following_them((prev) => !prev);

    if (hasData(id)) {
      const res = await fetch(`/api/private/user-follow/insert?id=${id}`);
      const json = await res.json();
      if (json) {
        // console.log("json",json)
        const { action, status } = json;
        //console.log("follow status", status)

        set_state_following_them_status(status);
        if (action >= 1) {
          await set_state_following_them(true);
        } else {
          await set_state_following_them(false);
        }
      }
    }
  }
};

export const notifyHandler = async (
  id,
  user_name,
  bol_notify_options,
  notify_options_bitarray,
  notify_show_array
) => {
  if (hasData(id)) {
    const res = await fetch(`/api/private/user-follow/update?id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        bol_notify_options: bol_notify_options,
        notify_options_bitarray: notify_options_bitarray,
        notify_show_array: JSON.stringify(notify_show_array),
      }),
    });

    const json = await res.json();
    if (json) {
      // console.log("json",json)
      const { outcome } = json;
      if (outcome == 1) {
        toast(`Saved!`, {
          time: 1000,
          className: "fixed left-[0px] bottom-[0px] ",
          clickable: true,
          clickClosable: false,
        });
      } else {
        toast(
          `Something went wrong while saving your settings for ${user_name}. Please try again.`,
          { time: 3000, className: "", clickable: true, clickClosable: false }
        );
      }
    }
  }
};

export const followApprove = async (id, status) => {
  const api_str = `/api/private/user-follow/approve?id=${id}&status=${status}`;
  //console.log("api_str",api_str)
  if (hasData(id)) {
    const res = await fetch(api_str);
    const json = await res.json();
    if (json) {
      //console.log("json",json)
      const { action } = json;
      //console.log("approval status", status)
      toastConfig({
        time: 1000,
        className: "",
        position: "right",
      });
      toast(status == "1" ? `Follow request approved` : `Follow removed`, {
        time: 3000,
        className: "",
        clickable: true,
        clickClosable: true,
      });
    }
  }
};

//BLOCKS
export const blockHandler = async (
  owner_id,
  target_id,
  username,
  action,
  isConfirmed
) => {
  const confirmed = await confirm(
    `Are you sure you want to ${
      blockactions.filter((e) => e?.action == action)[0]?.action_short
    } ${username} ${
      blockactions.filter((e) => e?.action == action)[0]?.js_amount
    }?`
  );
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(target_id)) {
      const res = await fetch(
        `/api/private/user-block/insert?owner_id=${owner_id}&target_id=${target_id}&action=${action}`
      );
      const json = await res.json();

      if (json) {
        const { outcome } = json[0][0];
        //console.log(outcome)
        if (outcome >= 1) {
          toast(
            `${username} has been ${
              blockactions.filter((e) => e?.action == action)[0]?.action_past
            } ${
              blockactions.filter((e) => e?.action == action)[0]?.js_amount
            }.`,
            { time: 3000, className: "", clickable: true, clickClosable: false }
          );
        } else {
          toast(`You dont have permission to do that.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};

//IGNORE
export const ignoreHandler = async (
  target_id,
  action,
  target_name,
  isConfirmed,
  authContext,
  set_isIgnored
) => {
  const confirmed = await confirm(
    `Are you sure you want to ${
      action == "1" ? `add ${target_name} to` : `remove ${target_name} from`
    } your private ignore list?`
  );
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(target_id)) {
      const res = await fetch(
        `/api/private/user-ignore/insert?target_id=${target_id}&action=${action}`
      );
      const json = await res.json();
      if (json) {
        const { outcome, ignore_json } = json[0][0];

        await authContext.set_userContextValue({
          ...authContext,
          ignore_json: ignore_json,
        });

        if (action == "1") {
          await set_isIgnored(true);
        } else {
          await set_isIgnored(false);
        }

        if (outcome?.toString() == "1") {
          toast(
            `${target_name} has been ${
              action == "1" ? "added to" : "removed from"
            } your private ignore list. You can manage ignored users in your personal settings.`,
            { time: 3000, className: "", clickable: true, clickClosable: false }
          );
        } else {
          toast(`Something went wrong. Please try again.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};

//APPROVE LIST
export const approveHandler = async (
  target_id,
  action,
  target_name,
  isConfirmed,
  authContext,
  set_isApproved
) => {
  const confirmed = await confirm(
    `Are you sure you want to ${
      action == "1" ? `add ${target_name} to` : `remove ${target_name} from`
    } the approved chatters list?`
  );
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(target_id)) {
      const res = await fetch(
        `/api/private/user-approve/insert?target_id=${target_id}&action=${action}`
      );
      const json = await res.json();
      if (json) {
        const { outcome, approve_json } = json[0][0];

        await authContext.set_userContextValue({
          ...authContext,
          approve_json: approve_json,
        });

        if (action == "1") {
          await set_isApproved(true);
        } else {
          await set_isApproved(false);
        }

        if (outcome?.toString() == "1") {
          toast(
            `${target_name} has been ${
              action == "1" ? "added to" : "removed from"
            } the approved chatters list. You can manage approved chatters in your chat settings.`,
            { time: 3000, className: "", clickable: true, clickClosable: false }
          );
        } else {
          toast(`Something went wrong. Please try again.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};

//ADMIN
export const adminHandler = async (
  target_id,
  setting,
  type,
  target_name,
  isConfirmed
) => {
  const confirmed = await confirm(
    `Are you sure you want to ${type} for ${target_name}?`
  );
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (confirmed) {
    if (hasData(target_id)) {
      //alert(`/api/private/admin/actions?target_id=${target_id}&setting=${setting}&name=${target_name}`)
      const res = await fetch(
        `/api/private/admin/actions?target_id=${target_id}&setting=${setting}&name=${target_name}`
      );
      const json = await res.json();
      if (json) {
        // console.log("json",json)

        const { outcome } = json[0][0];

        if (["1", "0"].indexOf(outcome?.toString()) > -1) {
          toast(`"${type}" completed for ${target_name}.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          toast(`Something went wrong. Please try again.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};

//ADMIN
export const killHandler = async (target_id, target_name) => {
  const reason = await prompt(
    `Please provide a reason for killing ${target_name}'s active live stream:`,
    ""
  );
  // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
  if (reason !== null) {
    if (hasData(target_id)) {
      //alert(`/api/private/admin/actions?target_id=${target_id}&setting=${setting}&name=${target_name}`)
      const res = await fetch(
        `/api/private/broadcast/update-kill?id=${target_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reason: reason,
          }),
        }
      );
      const json = await res.json();
      if (json) {
        // console.log("json",json)

        const { outcome } = json[0][0];

        if (["1", "0"].indexOf(outcome?.toString()) > -1) {
          toast(`${target_name}'s live stream has been killed.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        } else {
          toast(`Something went wrong. Please try again.`, {
            time: 3000,
            className: "",
            clickable: true,
            clickClosable: false,
          });
        }
      }
    }
  }
};
